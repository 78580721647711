// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.parentDocumentation{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
}

.GitBookDocumentation{
    display: block;
    width: 100%;
    height: 100vh;
}

.noScrollbar {
    overflow: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/css/Documentation.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,cAAc;IACd,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["\n.parentDocumentation{\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    margin: 0;\n}\n\n.GitBookDocumentation{\n    display: block;\n    width: 100%;\n    height: 100vh;\n}\n\n.noScrollbar {\n    overflow: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
