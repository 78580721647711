import UpdateAssetCode from "../runkit/UpdateAssetCode";
const EvolveAsset = ({ universeInfo, freeverseAPI }) => {
  return (
    <>
      <UpdateAssetCode
        universeInfo={universeInfo}
        freeverseAPI={freeverseAPI}
      />
    </>
  );
};

export default EvolveAsset;
