import { Outlet } from "react-router-dom";
import Header from "../components/sections/Header";
import Footer from "../components/sections/Footer";

function RootLayout() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex-grow-1">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default RootLayout;
