// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-width: 500px;  /* or whatever maximum width you want */
  }
  
  .modal-content h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .modal-content p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .modal-content button {
    display: block;
    margin: 0 auto;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #eee;
    transition: background-color 0.3s;
  }
  
  .modal-content button:hover {
    background-color: #ddd;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/AlertModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,2CAA2C;IAC3C,UAAU;IACV,gBAAgB,GAAG,uCAAuC;EAC5D;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,iCAAiC;EACnC;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .modal-content {\n    background-color: #ffffff;\n    padding: 30px;\n    border-radius: 12px;\n    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);\n    width: 50%;\n    max-width: 500px;  /* or whatever maximum width you want */\n  }\n  \n  .modal-content h3 {\n    font-size: 24px;\n    color: #333;\n    margin-bottom: 15px;\n    text-align: center;\n  }\n  \n  .modal-content p {\n    font-size: 16px;\n    color: #666;\n    margin-bottom: 10px;\n  }\n  \n  .modal-content button {\n    display: block;\n    margin: 0 auto;\n    background: none;\n    border: none;\n    color: #666;\n    cursor: pointer;\n    padding: 5px 10px;\n    border-radius: 5px;\n    background-color: #eee;\n    transition: background-color 0.3s;\n  }\n  \n  .modal-content button:hover {\n    background-color: #ddd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
