import { keyboard } from "@testing-library/user-event/dist/keyboard";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

/*
const getUserInfoByUserId = async (userId) => {
  try {
    const userDoc = await db.collection("users").doc(userId).get();
    if (!userDoc.exists) {
      console.log("No such user!");
      return null;
    }
    const { email, name, uid } = userDoc.data();
    return { email, name, uid };
  } catch (error) {
    console.error("Error getting user document:", error);
  }
};*/

const getUniverseInfoByUser = async (currentUser) => {
  const db = getFirestore();
  const universesRef = collection(db, "universes");

  const q = query(universesRef, where("userId", "==", currentUser.uid));

  try {
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const universeDetails = querySnapshot.docs[0].data();
      const {
        name,
        encrypted,
        password,
        "private-key": privateKey,
        "public-key": publicKey,
        "universe-id": universeId,
      } = universeDetails;

      return {
        universeName: name,
        encryptedPVK: encrypted,
        password: password,
        privateKey: privateKey,
        publicKey: publicKey,
        universeId: universeId,
      };
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export { getUniverseInfoByUser };
