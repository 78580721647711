// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5%;
}

.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  max-width: 400px;
  width: 100%;
  margin: 5%;
}

.register__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.register__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
  border-radius: 10px;
}
.register__google {
  background-color: #4285f4;
}
.register div {
  margin-top: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Register.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB","sourcesContent":[".register {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 5%;\n}\n\n.register__container {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  background-color: #ffffff;\n  padding: 30px;\n  border-radius: 12px;\n  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);\n  max-width: 400px;\n  width: 100%;\n  margin: 5%;\n}\n\n.register__textBox {\n  padding: 10px;\n  font-size: 18px;\n  margin-bottom: 10px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n}\n\n.register__btn {\n  padding: 10px;\n  font-size: 18px;\n  margin-bottom: 10px;\n  border: none;\n  color: white;\n  background-color: black;\n  border-radius: 10px;\n}\n.register__google {\n  background-color: #4285f4;\n}\n.register div {\n  margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
