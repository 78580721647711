const ActionInformation = ({ title, description }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p
          className="card-text"
          dangerouslySetInnerHTML={{
            __html: description.replace(/\n/g, "<br>"),
          }}
        ></p>
      </div>
    </div>
  );
};

export default ActionInformation;
