import React, { useState, useEffect, useContext, useCallback } from "react";
import "./../css/Scan.css";


function Scan() {

  
    useEffect(() => { 
        // When the component mounts, add a class to the body
        document.body.classList.add('noScrollbar'); // Hiding the scrollbar so we don't have 2 scrollbars together with the iframe

        // When the component unmounts, remove the class
        return () => {
            document.body.classList.remove('noScrollbar');
        };
    }, []); 

    return (
        <div class="parentScan">
            <iframe class="LivingAssetsScan" src="https://scan.staging.bigbang.gorengine.com/"/>
        </div>
    );
  }
  
  export default Scan;
  