import React, { useState, useEffect, useContext, useCallback } from "react";
import { getUniverseInfoByUser } from "./../utils/getUserInfo";
import { AuthContext, auth } from "./../utils/firebase";

function APIPlayground() {
  const currentUser = useContext(AuthContext);
  const [universeInfo, setUniverseInfo] = useState({}); // 1. Initialize with an empty object

  const fetchUserUniverseInfo = useCallback(async () => {
    const userUniverseInfo = await getUniverseInfoByUser(currentUser);
    console.log(userUniverseInfo);
    if (userUniverseInfo) {
        setUniverseInfo(userUniverseInfo);
    }
  }, [currentUser.uid]);

  useEffect(() => {
    fetchUserUniverseInfo();
  }, [fetchUserUniverseInfo]); // Dependency on the callback

  useEffect(() => {
    fetchUserUniverseInfo();
  }, []); // Run once when the component mounts

  let srcconst = "";
  if (universeInfo && universeInfo.universeId) {
  srcconst =`https://livingassets.s3.amazonaws.com/assets/index_graphiql.html?query1=query%20AllAssets%20%7B%0A%20%20allAssets(condition:%20%7BuniverseId:%20${universeInfo.universeId}%7D)%20%7B%0A%20%20%20%20nodes%20%7B%0A%20%20%20%20%20%20id%0A%20%20%20%20%20%20props%0A%20%20%20%20%20%20updatedAt%0A%20%20%20%20%20%20createdAt%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D&query2=query%20AllBuynows%20%7B%0A%20%20allBuynows(condition:%20%7BsellerId:%20%22${universeInfo.publicKey}%22%7D)%20%7B%0A%20%20%20%20nodes%20%7B%0A%20%20%20%20%20%20id%0A%20%20%20%20%20%20assetId%0A%20%20%20%20%20%20sellerId%0A%20%20%20%20%20%20price%0A%20%20%20%20%20%20currencyId%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D&query3=query%20AllCryptoCurrencies%20%7B%0A%20%20allSupportedCryptocurrencies%20%7B%0A%20%20%20%20nodes%20%7B%0A%20%20%20%20%20%20currencyId%0A%20%20%20%20%20%20symbol%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D&query4=query%20AllFiatCurrencies%20%7B%0A%20%20allSupportedFiatcurrencies%20%7B%0A%20%20%20%20nodes%20%7B%0A%20%20%20%20%20%20currencyId%0A%20%20%20%20%20%20symbol%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D`;
  }

  return (
    <>
      <iframe
        src = {srcconst}
        style={{ width: "100%", height: "900px" }}
        title="API Playground"
      />
    </>
  );
}

export default APIPlayground;
