import React from "react";
import "../../css/Spinner.css";

class LinkAccountCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true // Initialize loading state as true
        };
      }
  async componentDidMount() {
    // Dynamically load the RunKit script
    const script = document.createElement("script");
    console.log();
    script.src = "https://embed.runkit.com";
    script.async = true;
    script.onload = () => this.createNotebook();
    document.body.appendChild(script);
  }
  createNotebook() {
    if (!this.notebook) {
      const element = document.getElementById("runkit-container");
      this.notebook = window.RunKit.createNotebook({
        element,
        source: `
console.log('Loading dependencies (takes a while on Runkit)');
const utf8validate = require('utf-8-validate');
const identity = require('freeverse-crypto-js');
const { digestLinkAddress, sign, getTokenDigest, composeToken } = require('freeverse-marketsigner-js');
require('graphql');
const { GraphQLClient, gql } = require('graphql-request');
const Accounts = require('web3-eth-accounts');

const email = '${this.props.userEmail}';
const alias = 'my first account';
const endpoint = 'https://api.staging.blackhole.gorengine.com/';

// In this example, the universe pvk is used
// You will need to use the user own web3 wallet in your app.
const pvk = '${this.props.universeInfo.privateKey}';
const userAccount = identity.accountFromPrivateKey(pvk);
const userWeb3Address = userAccount.address;

// Compute the digest to be signed:
const digest = digestLinkAddress({
  email,
  web3Address: userWeb3Address,
});
const signaturemutation = sign({ digest, web3account: userAccount });
const signatureWithout0x = signaturemutation.substring(2, signaturemutation.length);

// inject results into final mutation to send to graphQL endpoint
const linkMutation = \`
mutation {
  linkWeb3AddressToEmail(
    input: {
      email: "\${email}",
      alias: "\${alias}",
      web3Address: "\${userWeb3Address}",
      signature: "\${signatureWithout0x}",
      universeName: "My Amazing App",
      language: "en",
    }
  )
}\`;
console.log(linkMutation);

console.log("Generating b2b auth token")
const nowdecimal = new Date().getTime() / 1000 ;
const now = nowdecimal | 0;
const tokenDigest = getTokenDigest({ time: now });
const signature = Accounts.sign(tokenDigest, pvk);
const token = composeToken({ time: now, sig: signature.signature });

// the GraphQL Client, used to send and receive data
const graphQLClient = new GraphQLClient(endpoint, {
    headers: {Authorization: \`Freeverse \${token}\`},
    jsonSerializer: {
    parse: JSON.parse,
    stringify: JSON.stringify,
    },
});
const run = async () => {
  // send mutation to the endpoint to execute
  try {
  const mutationResponse = await graphQLClient.request(linkMutation);
  console.log("Address linked successfully!");
  }
  catch(error){
    console.log("Something went wrong",error);
  } 
}
run();

`,
        onLoad: () => {
          // Once the notebook is fully loaded and rendered, set loading to false
          this.setState({ loading: false });
        },
        nodeVersion: "18.x.x",
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="spinner"></div>
          </div>
        )}
        <div id="runkit-container"></div>
      </div>
    );
  }
}

export default LinkAccountCode;
