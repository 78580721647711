import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./../css/Dashboard.css";
import { auth, db, logout } from "../utils/firebase";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import UniverseDetails from "../components/UniverseDetails";
import { Container, Row, Col, Card, Form, Button, Modal } from 'react-bootstrap';
import Switch from "react-switch";
import marketplaceImage from './../components/img/marketplace.png';
import APIPlaygroundImage from './../components/img/API_playground.png';
import documentationImage from './../components/img/Documentation.png';
import assetEditorImage from './../components/img/AssetEditor.png';
import contactUsImage from './../components/img/contactUs.png';


function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // New state for success message
  const [formSubmitted, setFormSubmitted] = useState(false); // New state for tracking form submission





  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const db = getFirestore();
    let formData = {
      email: email,
      message: message
    };
  
    const formSubmissions = collection(db, "formSubmissions");
    await addDoc(formSubmissions, formData);
    setEmail("");
    setMessage("");
    setSuccessMessage("Form submitted successfully!"); // Set success message
    setFormSubmitted(true); // Set form submission to true
  };

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      fetchUserName();
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading, navigate]);

  const handleSwitchChange = (checked) => {
    setChecked(checked);
    if (checked) setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setChecked(false);
    setSuccessMessage(""); // Clear success message when modal closes
    setFormSubmitted(false); // Reset form submission when modal closes
  };

  return (
<Container className="dashboard">
      <Row className="d-flex">
        <Col xs={12} md={3} className="align-self-center">
          <div className="loged_as_container">
            <h2>Logged in as</h2>
            <p>{user?.email}</p>
            <div className="d-flex align-items-center ">
              <Form.Check 
                type="switch"
                id="custom-switch"
                checked={checked}
                onChange={handleSwitchChange}
              />
              <div className="ml-2">{checked ? "Production Environment" : "Sandbox Environment"}</div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Sales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Conditionally display success message or form */}
          {formSubmitted ? (
            <div>{successMessage}</div>
          ) : (
            <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Your message" value={message} onChange={handleMessageChange} />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
          )}
          </Modal.Body>
            </Modal>
            </div>
          </Col>
          <Col xs={12} md={9} >
          <div className="dashboard__block" >
            <UniverseDetails/>
          </div>
          </Col>
      </Row>
      <Row>
          <Col xs={4} md={3}>
            <div className="dashboard__box" style={{backgroundImage: `url(${contactUsImage})`, backgroundSize: 'cover', backgroundPosition: 'center'}} onClick={() => window.open('https://www.freeverse.io/contact', '_blank')}>
            </div>
          </Col>
          <Col xs={4} md={3}>
            <div className="dashboard__box" style={{backgroundImage: `url(${APIPlaygroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center'}} onClick={() => navigate('/api-playground')}>
            </div>
          </Col>
          <Col xs={4} md={3}>
            <div className="dashboard__box" style={{backgroundImage: `url(${documentationImage})`, backgroundSize: 'cover', backgroundPosition: 'center'}} onClick={() => navigate('/documentation')}>
            </div>
          </Col>
          <Col xs={4} md={3}>
            <div className="dashboard__box" style={{backgroundImage: `url(${assetEditorImage})`, backgroundSize: 'cover', backgroundPosition: 'center'}} onClick={() => window.open('https://cerberus.staging.blackhole.gorengine.com/', '_blank')}>
            </div>
          </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
