import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import { AuthContext, auth } from "../../utils/firebase";
import { signOut } from "firebase/auth";
import { Navbar, Nav, Container } from "react-bootstrap";

function Header() {
  const currentUser = useContext(AuthContext);
  const handleSignOut = async () => {
    try {
      await signOut(auth); // Sign out the current user
    } catch (error) {
      console.error("Sign out Error:", error);
    }
  };

  return (
    <header className="header">
      <Container fluid>
        <Navbar expand="lg">
          <Navbar.Brand href="/">
            <img className="logo-img" src="/logo.png" alt="logo"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <div className="navbar-collapse-wrapper">
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                {currentUser ? (
                  <>
                    <NavLink
                      to="/dashboard"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Dashboard
                    </NavLink>
                    <NavLink
                      to="/documentation"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Docs
                    </NavLink>
                    <NavLink
                      to="/actions"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Examples
                    </NavLink>
                    <NavLink
                      to="/api-playground"
                      className="nav-link"
                      activeClassName="active"
                    >
                      API
                    </NavLink>
                    <NavLink
                      to="/scan"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Scan
                    </NavLink>
                    <NavLink
                      to="/"
                      onClick={handleSignOut}
                      className="nav-link"
                      activeClassName="active"
                    >
                      Logout
                    </NavLink>
                  </>
                ) : (
                  <NavLink
                    to="/register"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Sign In
                  </NavLink>
                )}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </Container>
    </header>
  );
}

export default Header;
