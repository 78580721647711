import React from "react";
import "../../css/Spinner.css";


class CreateAssetCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            runkitOutput: '',
            returnedMessage: '', // New state property
        };
    }

    handleRunkitMessage = (event) => {
        if (event.data && event.data.type === 'RUNKIT_RESULT') {
            this.setState({
                runkitOutput: JSON.stringify(event.data.data, null, 2),
                loading: false
            });
        }
    }
    
  createNotebook() {
    if (!this.notebook) {
      const element = document.getElementById("runkit-container");
      this.notebook = window.RunKit.createNotebook({
        element,
        source: 
`
console.log('Starting to load all the required dependencies');

const owner = '${this.props.universeInfo?.publicKey}';

// dependencies
require("utf-8-validate");
const identity = require('freeverse-crypto-js');
const { createAssetOp, AtomicAssetOps } = require("freeverse-apisigner-js");
require('graphql');
const { GraphQLClient, gql } = require('graphql-request');
console.log('All required dependencies have been successfully loaded');

// These variables are prefilled from your account details
const pvk = '${this.props.universeInfo?.privateKey}';
const uni = '${this.props.universeInfo?.universeId}';
const endpoint = 'https://api.staging.blackhole.gorengine.com/';

// the GraphQL Client, used to send and receive data
const graphQLClient = new GraphQLClient(endpoint, {
    jsonSerializer: {
    parse: JSON.parse,
    stringify: JSON.stringify,
    },
});

const run = async () => {
    // request the current nonce of the asset from the Living Assets API
    // request the nonce from the Living Assets API
    const data = await graphQLClient.request(
// query
gql\`
query( $userId: String! ) {
    usersUniverseByUserIdAndUniverseId(
        universeId: ${this.props.universeInfo?.universeId},
        userId: $userId
    ) {
        nonce
    }
}\`,
// variables
{
    userId: owner,
},
);
// if user is not found, query returns null
// so we protect against this by setting default value to 0
let ownerNonce = 0;
    if (data.usersUniverseByUserIdAndUniverseId && data.usersUniverseByUserIdAndUniverseId.nonce) {
    ownerNonce = data.usersUniverseByUserIdAndUniverseId.nonce
}

// create web3 account from the universe owner private key
const universe_owner_account = identity.accountFromPrivateKey(pvk);

const assetOps = new AtomicAssetOps({ universeId: uni });
const operation = createAssetOp({
    nonce: ownerNonce,
    ownerId: owner,
    props: new_asset_props,
    metadata: new_asset_metadata,
});

assetOps.push({ op: operation });
const sig = assetOps.sign({ web3Account: universe_owner_account });
const mutation = assetOps.mutation({ signature: sig });

// the final mutation
//console.log(mutation);

// send it to the endpoint to execute
const mutationResponse = await graphQLClient.request(mutation);
const newAssetIdString = mutationResponse.execute.results[0]; // Take the first element of the array
const newAssetIdObject = JSON.parse(newAssetIdString); // Parse the string to get an object
newAssetId = newAssetIdObject.id; // Extract the "id" property
// const LASLink = "https://scan.staging.bigbang.gorengine.com/asset/" + newAssetId;
return(newAssetId); // use console.log() if done outside of runkit
}

// These will be the new properties of the asset, feel free to edit as you see fit
const new_asset_props = {
    name: 'Supercool Dragon',
    description: 'Legendary creature that loves ice.',
    image: 'ipfs://QmPCHHeL1i6ZCUnQ1RdvQ5G3qccsjgQF8GkJrWAm54kdtB',
    animation_url: 'ipfs://QmefzYXCtUXudCy9LYjU4biapHJiP26EGYS8hQjpei472j',
    attributes: [
        {
        trait_type: 'Rarity',
        value: 'Common',
        },
        {
        trait_type: 'Level',
        value: 10,
        },
        {
        trait_type: 'Weight',
        value: 223.5,
        },
    ],
};
// This is the new metadata - this is private to the universe owner
const new_asset_metadata = {
    private_data: 'that has been updated',
};

run();    
                `,
                onLoad: () => {
                    this.setState({ loading: false });
                },
        nodeVersion: "18.x.x",
      });
    }
  }


  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://embed.runkit.com";
    script.async = true;
    script.onload = () => this.createNotebook();
    document.body.appendChild(script);

}


executeNotebook = () => {
    if (this.notebook) {
        this.notebook.evaluate("run()").then(result => {
            this.setState({ 
                runkitOutput: JSON.stringify(result, null, 2), 
                loading: false,
                returnedMessage: result.mutationResponse || 'No message returned'
            });
        }).catch(error => {
            this.setState({ runkitOutput: error.toString(), loading: false });
        });
    }
}


render() {
    return (
        <div>
            {this.state.loading && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <div className="spinner"></div>
                </div>
            )}
            <div id="runkit-container"></div>
        </div>
    );
}

}

export default CreateAssetCode;
