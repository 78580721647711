// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: lightgrey;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #D9D8D6 !important;
  height: 100%;
  overflow: auto;
}


/* This targets the scrollbar container */
::-webkit-scrollbar {
  width: 10px; /* Adjust width for vertical scrollbar */
  height: 10px; /* Adjust height for horizontal scrollbar */
}

/* This targets the scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 4px; /* Rounded corners */
}

/* This targets the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

/* This targets the track of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 4px; /* Rounded corners */
}

/* This targets the scrollbar in Firefox */
* {
  scrollbar-width: thin; /* 'thin' or 'auto' or 'none' */
  scrollbar-color: #888 #f1f1f1; /* First color is the thumb, second is the track */
}
`, "",{"version":3,"sources":["webpack://./src/css/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,4BAA4B;EAC5B,4BAA4B;EAC5B,sBAAsB;EACtB,oCAAoC;EACpC,YAAY;EACZ,cAAc;AAChB;;;AAGA,yCAAyC;AACzC;EACE,WAAW,EAAE,wCAAwC;EACrD,YAAY,EAAE,2CAA2C;AAC3D;;AAEA,sCAAsC;AACtC;EACE,sBAAsB,EAAE,2BAA2B;EACnD,kBAAkB,EAAE,oBAAoB;AAC1C;;AAEA,wCAAwC;AACxC;EACE,sBAAsB,EAAE,0BAA0B;AACpD;;AAEA,4CAA4C;AAC5C;EACE,yBAAyB,EAAE,uBAAuB;EAClD,kBAAkB,EAAE,oBAAoB;AAC1C;;AAEA,0CAA0C;AAC1C;EACE,qBAAqB,EAAE,+BAA+B;EACtD,6BAA6B,EAAE,kDAAkD;AACnF","sourcesContent":["body {\n  background: lightgrey;\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n  background-size: cover;\n  background-color: #D9D8D6 !important;\n  height: 100%;\n  overflow: auto;\n}\n\n\n/* This targets the scrollbar container */\n::-webkit-scrollbar {\n  width: 10px; /* Adjust width for vertical scrollbar */\n  height: 10px; /* Adjust height for horizontal scrollbar */\n}\n\n/* This targets the scrollbar itself */\n::-webkit-scrollbar-thumb {\n  background-color: #888; /* Color of the scrollbar */\n  border-radius: 4px; /* Rounded corners */\n}\n\n/* This targets the scrollbar on hover */\n::-webkit-scrollbar-thumb:hover {\n  background-color: #555; /* Darker color on hover */\n}\n\n/* This targets the track of the scrollbar */\n::-webkit-scrollbar-track {\n  background-color: #f1f1f1; /* Color of the track */\n  border-radius: 4px; /* Rounded corners */\n}\n\n/* This targets the scrollbar in Firefox */\n* {\n  scrollbar-width: thin; /* 'thin' or 'auto' or 'none' */\n  scrollbar-color: #888 #f1f1f1; /* First color is the thumb, second is the track */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
