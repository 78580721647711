import React from "react";
import "../../css/Spinner.css";
import axios from "axios";

class PutForSaleCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // Initialize loading state as true
      axios: axios,
    };
  }
  async componentDidMount() {
    // Dynamically load the RunKit script
    let lastAssetId = await this.props.freeverseAPI.getLastAssetIdByUniverse();

    if (!lastAssetId || lastAssetId.length === 0) {
      lastAssetId = "ENTER_ASSET_ID";
    } else {
      lastAssetId = lastAssetId[0]; // Take the first asset ID from the array
    }
    this.setState({ lastAssetId });

    const script = document.createElement("script");
    script.src = "https://embed.runkit.com";
    script.async = true;
    script.onload = () => this.createNotebook();
    document.body.appendChild(script);
  }

  createNotebook() {
    if (!this.notebook) {
      const element = document.getElementById("runkit-container");
      this.notebook = window.RunKit.createNotebook({
        element,
        source: 
`/*
PUT FOR SALE AN ASSET
This example puts for sale any asset with the price and timeline that are hardcoded below.
The only variable that needs editing is the asset ID below:
*/

console.log('Setting up basic parameters');
const assetId = '${this.state.lastAssetId}';
const currencyId = 5; // 5 is MaticMumbai
const price = '25000000000000000000'; // 1 Matic has 18 decimal places (wei)


/*
All the remaining variables are prefilled.
For more information see:
https://github.com/freeverseio/examples/blob/main/nodejs/asset_update.js
*/

console.log('Setting up prefilled variables');
// These variables are prefilled from your account details
const pvk = '${this.props.universeInfo?.privateKey}';
const endpoint = 'https://api.staging.blackhole.gorengine.com/';

// Dependencies
console.log('Loading dependencies (takes a while on Runkit)');
const utf8validate = require('utf-8-validate');
const { composeToken, getTokenDigest, digestPutForSaleBuyNow, sign, getExpiryData } = require('freeverse-marketsigner-js');
const identity = require('freeverse-crypto-js');
require('graphql');
const { GraphQLClient, gql } = require('graphql-request');
const Accounts = require('web3-eth-accounts');


// Functions to calculate automatically the random number
function generateRandomNumber() {
  return Math.floor(Math.random() * 2147483647);
}

// These variables are prefilled automatically with the below functions.
const rnd = generateRandomNumber(); // random number of 10 digits
const timeValidUntil = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).getTime(); // valid until now + 30 days


// Convert timeValidUntil from secs to verse: providing some initial data
const expirationData = getExpiryData({
  time: timeValidUntil,
    verseInterval: 900, // 15 min
    // referenceTime: 1631531810, // Monday, 13 September 2021 11:16:50 - Valid for the production environment
    referenceTime: 1689242639, // (Jul-13-2023 10:03:59 AM +UTC) - Valid for the sandbox environment
    referenceVerse: 1,
    safetyDeadlineMargin: 300, // 5 min
});

// And use the verse as parameter to sign:
const validUntil = expirationData.lastValidVerse;

// The digest can finally be built:
const digest = digestPutForSaleBuyNow({
  currencyId, price, rnd, validUntil, assetId,
});

// create web3 account from your private key
// (other forms of creating web3 account could be substituted)

const assetOwnerAccount = identity.accountFromPrivateKey(pvk);
const signaturemutation = sign({ digest, web3account: assetOwnerAccount });
const signatureWithout0x = signaturemutation.substring(2, signaturemutation.length);
// the final mutation
const putForSaleMutation = \`
mutation { 
  createBuyNowFromPutForSale(
    input: { 
      assetId: "\${assetId}", 
      currencyId: \${currencyId}, 
      price: "\${price}", 
      rnd: \${rnd}, 
      validUntil: "\${validUntil}",
      signature: "\${signatureWithout0x}",
    }
  )
}\`;

console.log("Generating b2b auth token")
const nowdecimal = new Date().getTime() / 1000 ;
const now = nowdecimal | 0;
const tokenDigest = getTokenDigest({ time: now });
const signature = Accounts.sign(tokenDigest, pvk);
const token = composeToken({ time: now, sig: signature.signature });

// the GraphQL Client, used to send and receive data
const graphQLClient = new GraphQLClient(endpoint, {
    headers: {Authorization: \`Freeverse \${token}\`},
    jsonSerializer: {
    parse: JSON.parse,
    stringify: JSON.stringify,
    },
});
const run = async () => {

  // send mutation to the endpoint to execute
  try {
  const mutationResponse = await graphQLClient.request(putForSaleMutation);

  console.log("Put for sale created successfully!");
  console.log("Buynow id:", mutationResponse);

  }
  catch(error){
    console.log("Something went wrong",error);
  } 
} 
run();
`,
        onLoad: () => {
          // Once the notebook is fully loaded and rendered, set loading to false
          this.setState({ loading: false });
        },
        nodeVersion: "18.x.x",
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="spinner"></div>
          </div>
        )}
        <div id="runkit-container"></div>
      </div>
    );
  }
}

export default PutForSaleCode;
