import "./../css/NoCode.css";

function NoCode() {
  return (
    <>
      <iframe
        src="https://cerberus.staging.blackhole.gorengine.com/"
        style={{ width: "100%", height: "900px" }}
        title="Cerberus"
      />
    </>
  );
}

export default NoCode;
