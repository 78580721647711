const { request, gql } = require("graphql-request");

class FreeverseAPI {
  constructor(owner, pvk, universe, endpoint) {
    this.owner = owner;
    this.pvk = pvk;
    this.universe = universe;
    this.endpoint = endpoint;
  }

  async getUserNonce(freeverseId = this.owner) {
    const { endpoint, universe } = this;
    const query = gql`
      query ($freeverseId: String!, $universe: Int!) {
        usersUniverseByUserIdAndUniverseId(
          universeId: $universe
          userId: $freeverseId
        ) {
          nonce
        }
      }
    `;
    const result = await request(endpoint, query, {
      freeverseId,
      universe,
    });
    const { nonce = 0 } = result?.usersUniverseByUserIdAndUniverseId || {};
    return parseInt(nonce);
  }

  async getCollectionsIds(universeId, endpoint) {
    const query = gql`
      query ($universeId: Int!) {
        allCollections(condition: { universeId: $universeId }) {
          edges {
            node {
              id
            }
          }
        }
      }
    `;
    const result = await request(endpoint, query, {
      universeId,
    });
    const allCollections = result?.allCollections.edges.map(
      (item) => item.node.id
    );
    return allCollections;
  }
  async getCollectionImage(collectionId) {
    const { endpoint } = this;
    const query = gql`
      query ($collectionId: Int!) {
        collectionById(id: $collectionId) {
          imageUrl
        }
      }
    `;
    const result = await request(endpoint, query, {
      collectionId,
    });
    const { imageUrl = null } = result?.collectionById || {};
    return imageUrl;
  }

  async getCollectionName(collectionId) {
    const { endpoint } = this;
    const query = gql`
      query ($collectionId: Int!) {
        collectionById(id: $collectionId) {
          name
        }
      }
    `;
    const result = await request(endpoint, query, {
      collectionId,
    });
    const { name = null } = result?.collectionById || {};
    return name;
  }

  async getCollectionDescription(collectionId) {
    const { endpoint } = this;
    const query = gql`
      query ($collectionId: Int!) {
        collectionById(id: $collectionId) {
          description
        }
      }
    `;
    const result = await request(endpoint, query, {
      collectionId,
    });
    const { description = null } = result?.collectionById || {};
    return description;
  }

  async getCollectionFields(collectionId) {
    const { endpoint } = this;
    const query = gql`
      query ($collectionId: Int!) {
        collectionById(id: $collectionId) {
          name
          description
          imageUrl
        }
      }
    `;
    const result = await request(endpoint, query, {
      collectionId,
    });
    const {
      name = null,
      description = null,
      imageUrl = null,
    } = result?.collectionById || {};
    return { name, description, imageUrl };
  }
  async getCollectionNonce(collectionId) {
    const { endpoint } = this;
    const query = gql`
      query ($collectionId: Int!) {
        collectionById(id: $collectionId) {
          nonce
        }
      }
    `;
    const result = await request(endpoint, query, {
      collectionId,
    });
    const { nonce = 0 } = result?.collectionById || {};
    return parseInt(nonce);
  }

  async getAssetNonce(id) {
    const { endpoint } = this;
    const query = gql`
      query ($id: String!) {
        assetById(id: $id) {
          nonce
        }
      }
    `;
    const result = await request(endpoint, query, {
      id,
    });
    const { nonce = 0 } = result?.assetById || {};
    return parseInt(nonce);
  }
  async getAssetsIdsByCollection(universeId, collectionId) {
    const { endpoint } = this;
    const query = gql`
      query ($universeId: Int!, $collectionId: Int!) {
        allAssets(
          condition: { universeId: $universeId, collectionId: $collectionId }
        ) {
          edges {
            node {
              id
            }
          }
        }
      }
    `;
    const result = await request(endpoint, query, {
      universeId,
      collectionId,
    });
    const allAssets = result?.allAssets.edges.map((item) => item.node.id);
    return allAssets;
  }

  async getLastAssetIdByUniverse(universeId = this.universe) {
    const { endpoint } = this;
    const query = gql`
      query ($universeId: Int!) {
        allAssets(condition: { universeId: $universeId }, last: 1) {
          edges {
            node {
              id
            }
          }
        }
      }
    `;
    const result = await request(endpoint, query, {
      universeId,
    });
    const lastAsset = result?.allAssets.edges.map((item) => item.node.id);
    return lastAsset;
  }

  async getAsset(id) {
    const { endpoint } = this;
    const query = gql`
      query ($id: String!) {
        assetById(id: $id) {
          props
          metadata
        }
      }
    `;

    const result = await request(endpoint, query, {
      id,
    });
    const { props, metadata } = result?.assetById || {};
    return {
      props: props && JSON.parse(props),
      metadata: metadata && JSON.parse(metadata),
    };
  }
  /*
  async createAsset({ ownerId = this.owner, props = {}, metadata = {} } = {}) {
    const { endpoint, universe, pvk } = this;

    const nonce = await this.getUserNonce(ownerId);
    const assetOps = new AtomicAssetOps({ universeId: universe });
    const op = createAssetOp({
      nonce,
      ownerId,
      props,
      metadata,
    });
    assetOps.push({ op });
    const web3Account = identity.accountFromPrivateKey(pvk);
    const signature = assetOps.sign({ web3Account });
    const mutation = assetOps.mutation({ signature });

    return request(endpoint, mutation).then((response) => {
      console.log("#### Create Response:", response);
      const result = response.execute.results.map(
        (result) => JSON.parse(result).id
      );
      return result[0];
    });
  }

  async updateAsset({ assetId, props = {}, metadata = {} } = {}) {
    const { endpoint, universe, pvk } = this;
    const nonce = await this.getAssetNonce(assetId);
    const assetOps = new AtomicAssetOps({ universeId: universe });
    const updatedAsset = {
      nonce,
      assetId,
      props,
      metadata,
    };
    const op = updateAssetOp(updatedAsset);
    assetOps.push({ op });
    const web3Account = identity.accountFromPrivateKey(pvk);
    const signature = assetOps.sign({ web3Account });
    const mutation = assetOps.mutation({ signature });
    //  console.log("Printing mutation");
    //console.log(mutation);
    return request(endpoint, mutation).then((response) => {
      const result = response.execute.results.map((result) =>
        JSON.parse(result)
      );
      return result[0];
    });
  }

  async setPropsMultiple(
    assets,
    props1,
    overwriteAttributes = true,
    metadata = {}
  ) {
    assets.forEach((assetId) => {
      //delete the reference pointer since "propsvar" changes
      let props = JSON.parse(JSON.stringify(props1));
      let asset = this.getAsset(assetId);

      asset.then(function (result) {
        props.name
          ? console.log("name updated")
          : (props.name = result.props.name);
        props.description
          ? console.log("description updated")
          : (props.description = result.props.description);
        props.image
          ? console.log("image updated")
          : (props.image = result.props.image);
        props.collectionId
          ? console.log("collectionId updated")
          : (props.collectionId = result.props.collectionId);

        if (overwriteAttributes) {
          props.attributes
            ? console.log("attributes updated")
            : (props.attributes = result.props.attributes);
        } else {
          if (props.attributes) {
            console.log("attributes updated");
            props.attributes.forEach(function (newAttribute, indexProps) {
              let existsAttribute = false;
              result.props.attributes.forEach(function (
                originalAttribute,
                indexResult
              ) {
                if (newAttribute.trait_type == originalAttribute.trait_type) {
                  result.props.attributes[indexResult].value =
                    newAttribute.value;
                  existsAttribute = true;
                }
              });
              if (!existsAttribute) {
                result.props.attributes.push(newAttribute);
                existsAttribute = false;
              }
            });
          }
          props.attributes = result.props.attributes;
        }
        this.updateAsset({ assetId, props, metadata });
      });
    });
  }
  async setProps({
    assetId,
    props = {},
    overwriteAttributes = false,
    metadata = {},
  } = {}) {
    const newProps = JSON.parse(JSON.stringify(props));
    const assetProps = await this.getAsset(assetId);
    let updatedProps = {};

    if (newProps.name) {
      //console.log("Name updated");
      updatedProps.name = newProps.name;
    } else {
      updatedProps.name = assetProps.props.name;
    }
    if (newProps.description) {
      //  console.log("Description updated");
      updatedProps.description = newProps.description;
    } else updatedProps.description = assetProps.props.description;
    if (newProps.image) {
      //  console.log("Image updated");
      updatedProps.image = newProps.image;
    } else updatedProps.image = assetProps.props.image;

    if (newProps.animation_url) {
      //  console.log("Animation URL updated");
      updatedProps.animation_url = newProps.animation_url;
    } else updatedProps.animation_url = assetProps.props.animation_url;

    if (newProps.collectionId) {
      // console.log("CollectionId updated");
      updatedProps.collectionId = newProps.collectionId;
    } else updatedProps.collectionId = assetProps.props.collectionId;
    if (overwriteAttributes) {
      // console.log("Attributes overwritten");
      updatedProps.attributes = newProps.attributes;
    } else {
      //we fill the updated props with the existing values to maintain the order of the attributes
      updatedProps.attributes = assetProps.props.attributes;

      if (newProps.attributes) {
        updatedProps.attributes = assetProps.props.attributes;
        newProps.attributes.forEach(function (newAttribute, indexProps) {
          let existsAttribute = false;
          assetProps.props.attributes.forEach(function (
            originalAttribute,
            indexOriginalAttribute
          ) {
            if (newAttribute.trait_type === originalAttribute.trait_type) {
              updatedProps.attributes[indexOriginalAttribute].value =
                newAttribute.value;
              existsAttribute = true;
            }
          });
          if (!existsAttribute) {
            updatedProps.attributes.push(newAttribute);
            existsAttribute = false;
          }
        });
      }
    }
    return this.updateAsset({ assetId, props: updatedProps, metadata });
  }

  async updateCollection(
    collectionId,
    universeId,
    collectionName,
    collectionDescription,
    imageUrl
  ) {
    const { endpoint, universe, pvk } = this;
    let nonce = await this.getCollectionNonce(collectionId);

    const web3Account = identity.accountFromPrivateKey(pvk);
    const sign = signUpdateCollection({
      web3Account,
      universeId,
      collectionId,
      name: collectionName,
      description: collectionDescription,
      imageUrl,
      nonce,
    });
    const signature = sign.signature.slice(2);
    const query = gql`
      mutation updateCollection(
        $collectionId: Int!
        $universeId: Int!
        $collectionName: String!
        $collectionDescription: String!
        $imageUrl: String!
        $signature: String!
        $nonce: Int!
      ) {
        updateCollection(
          input: {
            id: $collectionId
            universeId: $universeId
            name: $collectionName
            description: $collectionDescription
            imageUrl: $imageUrl
            signature: $signature
            nonce: $nonce
          }
        ) {
          id
        }
      }
    `;

    const result = request(endpoint, query, {
      collectionId,
      universeId,
      collectionName,
      collectionDescription,
      imageUrl,
      signature,
      nonce,
    }).then((response) => {
      console.log(`#### Updated Collection: ${response.updateCollection.id}`);
    });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }*/
}

export default FreeverseAPI;
