import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import "./../css/UniverseDetails.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const shortenField = (field) => {
  const start = field.substring(0, 4);
  const end = field.substring(field.length - 4);
  return `${start}...${end}`;
};

function UniverseDetails() {
  const [userId, setUserId] = useState(null);
  const [universeDetails, setUniverseDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userLoggedOut, setUserLoggedOut] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showEncryptedKey, setShowEncryptedKey] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        console.log("User ID:", user.uid);
        setLoading(true); // Start loading
        setUserLoggedOut(false);
        fetchUniverseDetails(user.uid);
      } else {
        setUserId(null);
        setUniverseDetails(null);
        setLoading(false); // End loading
        setUserLoggedOut(true);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const fetchUniverseDetails = async (uid) => {
    if (userLoggedOut) {
      return; // Exit if the user is logged out
    }

    setLoading(true); // Start loading

    const db = getFirestore();
    const universesRef = collection(db, "universes");
    const q = query(universesRef, where("userId", "==", uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const universeDetails = querySnapshot.docs[0].data();
      setUniverseDetails(universeDetails);
      setLoading(false); // End loading
    } else {
      console.log("No such document!");
      fetchUniverseDetails(uid); // Try to fetch again if document not found
    }
  };

  return (
    <div className="universe-details">
      <h3>Universe Details</h3>
      {loading && <Spinner animation="border" />}
      {userId && universeDetails && (
        <div>
          <div className="detail">

  <span>Universe Id:</span>
  <div className="content-container">
    {universeDetails['universe-id']}
  </div>
</div>
<div className="detail">
  <span>Universe Name:</span>
  <div className="content-container">
    {universeDetails['name']}
  </div>
</div>
<div className="detail">
  <span>Public key:</span>
  <div className="content-container">
    {shortenField(universeDetails['public-key'])}
  </div>
  <CopyToClipboard text={universeDetails['public-key']}>
    <button><FontAwesomeIcon icon={faCopy} /></button>
  </CopyToClipboard>
</div>
<div className="detail">
  <span>Private key:</span>
  <div className="content-container">
    <textarea readOnly className="detail-content" value={showPrivateKey ? universeDetails['private-key'] : '•••••••'} rows={showPrivateKey ? 3 : 1}>
    </textarea>
  </div>
  <button onClick={() => setShowPrivateKey(!showPrivateKey)}>
    <FontAwesomeIcon icon={showPrivateKey ? faEyeSlash : faEye} />
  </button>
  <CopyToClipboard text={universeDetails['private-key']}>
    <button><FontAwesomeIcon icon={faCopy} /></button>
  </CopyToClipboard>
</div>

<div className="detail">
  <span>Encrypted key:</span>
  <div className="content-container">
    <textarea readOnly className="detail-content" value={showEncryptedKey ? universeDetails['encrypted'] : '•••••••'} rows={showEncryptedKey ? 3 : 1}>
    </textarea>
  </div>
  <button onClick={() => setShowEncryptedKey(!showEncryptedKey)}>
    <FontAwesomeIcon icon={showEncryptedKey ? faEyeSlash : faEye} />
  </button>
  <CopyToClipboard text={universeDetails['encrypted']}>
    <button><FontAwesomeIcon icon={faCopy} /></button>
  </CopyToClipboard>
</div>

<div className="detail">
  <span>Password:</span>
  <div className="content-container">
    <textarea readOnly className="detail-content" value={showPassword ? universeDetails['password'] : '•••••••'} rows={showPassword ? 3 : 1}>
    </textarea>
  </div>
  <button onClick={() => setShowPassword(!showPassword)}>
    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
  </button>
  <CopyToClipboard text={universeDetails['password']}>
    <button><FontAwesomeIcon icon={faCopy} /></button>
  </CopyToClipboard>
</div>

<div className="detail">
  <span>API url:</span>
  <div className="content-container">
    <textarea readOnly className="detail-content" value="https://api.staging.blackhole.gorengine.com/" rows={showPassword ? 3 : 1}>
    </textarea>
  </div>
</div>

        </div>
      )}
    </div>
  );
}

export default UniverseDetails;
