import React from "react";
import UpdateAssetCode from "../components/runkit/UpdateAssetCode";

function API() {
  return (
    <>
      <div className="my-4 text-white text-center">
        <h3>Evolve Living Asset </h3>
      </div>

      <UpdateAssetCode />

      <div className="my-4 text-white text-center">
        <h3>Paste asset mutations in the GraphQL playground</h3>
      </div>

      <iframe
        src="https://api.staging.blackhole.gorengine.com/"
        style={{ width: "100%", height: "900px" }}
        title="Cerberus"
      />
    </>
  );
}

export default API;
