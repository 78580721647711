// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.nav-bar {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.whole-row {
  box-sizing: border-box !important;
  max-width: 100vw;
  display: flex;
  justify-content: space-evenly;
}


.margin-decimal {
  margin: 0.5rem !important; /* Adjust as needed */
}

.actions-block2{
  padding: 0px !important;
}

.card{
  min-height: 250px !important;
  border-bottom-color: transparent !important;
}

.actions-nav-item {
  padding: 15px 0 !important;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  color: #333;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.actions-nav-item:hover {
  background-color: #a19bad;
  color: whitesmoke;
}

.actions-nav-item.active {
  background-color: #7b6c9a;
  color: whitesmoke;
}

.content-area {
  overflow-y: auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}

#runkit-container {
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavigationBar/NavigationBar.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,wCAAwC;EACxC,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,aAAa;EACb,6BAA6B;AAC/B;;;AAGA;EACE,yBAAyB,EAAE,qBAAqB;AAClD;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;EAC5B,2CAA2C;AAC7C;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,eAAe;EACf,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["\n.nav-bar {\n  background-color: #ffffff;\n  border-radius: 12px;\n  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);\n  overflow-x: hidden;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.whole-row {\n  box-sizing: border-box !important;\n  max-width: 100vw;\n  display: flex;\n  justify-content: space-evenly;\n}\n\n\n.margin-decimal {\n  margin: 0.5rem !important; /* Adjust as needed */\n}\n\n.actions-block2{\n  padding: 0px !important;\n}\n\n.card{\n  min-height: 250px !important;\n  border-bottom-color: transparent !important;\n}\n\n.actions-nav-item {\n  padding: 15px 0 !important;\n  font-size: 18px;\n  cursor: pointer;\n  width: 100%;\n  color: #333;\n  text-align: center;\n  transition: background-color 0.3s ease, color 0.3s ease;\n}\n\n.actions-nav-item:hover {\n  background-color: #a19bad;\n  color: whitesmoke;\n}\n\n.actions-nav-item.active {\n  background-color: #7b6c9a;\n  color: whitesmoke;\n}\n\n.content-area {\n  overflow-y: auto;\n  padding: 30px;\n  background-color: #ffffff;\n  border-radius: 12px;\n  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);\n}\n\n#runkit-container {\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
