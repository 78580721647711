import LinkAccountCode from "../runkit/LinkAccountCode";
const LinkAccount = ({ universeInfo, freeverseAPI, userEmail }) => {
  return (
    <>
      <LinkAccountCode
        userEmail={userEmail}
        universeInfo={universeInfo}
        freeverseAPI={freeverseAPI}
      />
    </>
  );
};

export default LinkAccount;
