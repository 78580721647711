import Header from "../components/sections/Header";
import Footer from "../components/sections/Footer";
function Error() {
  return (
    <>
      <Header />
      <h1>Error 404: Page not found</h1>
      <Footer />
    </>
  );
}

export default Error;
