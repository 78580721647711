import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../pages/RootLayout";
import Login from "../components/Login";
import Register from "../components/Register";
import Reset from "../components/Reset";
import Dashboard from "../pages/Dashboard";
import APIPlayground from "../pages/APIPlayground";
import Error from "../pages/Error";
import NoCode from "../pages/NoCode";
import API from "../pages/API";
import Actions from "../pages/Actions";
import Documentation from "../pages/Documentation";
import Scan from "../pages/Scan";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      { path: "/", element: <Login /> },
      { path: "/login", element: <Login /> },
      { path: "/register", element: <Register /> },
      { path: "/reset", element: <Reset /> },
      { path: "/no-code", element: <NoCode /> },
      { path: "/api", element: <API /> },
      { path: "/actions", element: <Actions /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/api-playground", element: <APIPlayground /> },
      { path: "/documentation", element: <Documentation /> },
      { path: "/scan", element: <Scan /> },
    ],
  },
]);

export default router;
