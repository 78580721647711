import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";

function Footer() {
  return (
    <footer className="text-white py-3 mt-auto">
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-between align-items-center">
            <div className="text-light">
              <p className="mb-0">
                &copy; 2023 Freeverse. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;
