import React, { useState, useEffect, useContext, useCallback } from "react";
import CreateAsset from "./CreateAsset";
import EvolveAsset from "./EvolveAsset";
import PutForSale from "./PutForSale";
import LinkAccount from "./LinkAccount";
import "./NavigationBar.css";
import ActionInformation from "./ActionInformation";
import { AuthContext, auth } from "../../utils/firebase";
import { getUniverseInfoByUser } from "../../utils/getUserInfo";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import FreeverseAPI from "../../utils/FreeverseAPI";

const NavigationBar = () => {
  const currentUser = useContext(AuthContext);
  const API_URL = "https://api.staging.blackhole.gorengine.com/";

  const [activeSection, setActiveSection] = useState("link");
  const [userInfo, setuserInfo] = useState(null);
  const [universeInfo, setUniverseInfo] = useState(null);
  const [freeverseAPI, setFreeverseAPI] = useState(null);

  // Call the async function
  const fetchUserUniverseInfo = useCallback(async () => {
    const userUniverseInfo = await getUniverseInfoByUser(currentUser);
    if (userUniverseInfo) {
      setUniverseInfo(userUniverseInfo);
      setFreeverseAPI(
        new FreeverseAPI(
          userUniverseInfo.publicKey,
          userUniverseInfo.privateKey,
          parseInt(userUniverseInfo.universeId, 10),
          API_URL
        )
      );
    }
  }, [currentUser.uid]);

  useEffect(() => {
    fetchUserUniverseInfo();
  }, [fetchUserUniverseInfo]); // Dependency on the callback

  useEffect(() => {
    fetchUserUniverseInfo();
  }, []); // Run once when the component mounts

  let title = "";
  let description = "";

  switch (activeSection) {
    case "create":
      title = "Create Asset";
      description = `This example creates an asset with properties that are hardcoded. The only variable that needs editing is the owner ID below (leaving it to the default value will assign it to you, the universe owner).
All the remaining variables are prefilled. 

For more information see: <a target="_blank" href="https://github.com/freeverseio/examples/blob/main/nodejs/asset_create.js">Create Asset Code</a>

Click on <b>"run"</b> below to execute the code and create the asset`;

      break;
    case "evolve":
      title = "Evolve Asset";
      description = `This example updates any asset with properties that are hardcoded. The asset ID variable will be prefilled with the last asset you created. The variables "pvk", "uni" and "endpoint" do not need to be changed. 

For more information see: <a target="_blank" href="https://github.com/freeverseio/examples/blob/main/nodejs/asset_update.js">Evolve Asset Code</a>

Click on <b>"run"</b> below to execute the code and update the asset`;
      break;
    case "sell":
      title = "Put for Sale";
      description = "Use this section to put an asset up for sale.";
      break;
      case "link":
        title = "Link Account";
        description = `
Use this section to link a web3 address with an email (for minimal KYC). 

The request needs to be signed by the owner of the web3 address.
<b>note:</b> This mutation needs to include an authorization b2b token in the http header.

<b>INPUTS:</b>
- <b>email:</b> the email to be linked
- <b>alias:</b> an arbitrary string to describe the web3address
- <b>web3Address:</b> the web3 address to be linked
- <b>signature:</b> the signature by the web3 address authorizing the process of linking it to the email
- <b>universeName:</b> the name of the application that will appear in the email that the user will receive
- <b>language:</b> the language used in the email (e.g "en" for English)
`;
        break;
    default:
      description = description.replace(/\n/g, "<br>");
  }

  return (
    <Container fluid>
      <Row className="whole-row mt-4 mb-4">
        <Col xs={2} md={2} className="nav-bar actions-block2 actions-block">
        <div
            className={`actions-nav-item ${
              activeSection === "link" ? "active" : ""
            }`}
            onClick={() => setActiveSection("link")}
          >
            Link Account
          </div>
          <div
            className={`actions-nav-item ${
              activeSection === "create" ? "active" : ""
            }`}
            onClick={() => setActiveSection("create")}
          >
            Create Asset
          </div>
          <div
            className={`actions-nav-item ${
              activeSection === "evolve" ? "active" : ""
            }`}
            onClick={() => setActiveSection("evolve")}
          >
            Evolve Asset
          </div>
          {<div
            className={`actions-nav-item ${
              activeSection === "sell" ? "active" : ""
            }`}
            onClick={() => setActiveSection("sell")}
          >
            Put for Sale
          </div>}
        </Col>
        <Col xs={5} md={5} className="content-area actions-block">
        {activeSection === "link" && universeInfo && currentUser?.email && (
            <LinkAccount
              userEmail = {currentUser?.email}
              universeInfo={universeInfo}
              freeverseAPI={freeverseAPI}
            />
          )}
          {activeSection === "create" && universeInfo && (
            <CreateAsset universeInfo={universeInfo} />
          )}
          {activeSection === "evolve" && universeInfo && (
            <EvolveAsset
              universeInfo={universeInfo}
              freeverseAPI={freeverseAPI}
            />
          )}
          {activeSection === "sell" && universeInfo && (
            <PutForSale
              universeInfo={universeInfo}
              freeverseAPI={freeverseAPI}
            />
          )}
        </Col>
        <Col
          xs={4}
          md={4}
          className="content-area actions-block2 actions-block"
        >
          <ActionInformation title={title} description={description} />
        </Col>
      </Row>
    </Container>
  );
};

export default NavigationBar;
