import React from "react";
import "../../css/Spinner.css";

class UpdateAssetCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // Initialize loading state as true
      lastAssetId: "ENTER_ASSET_ID",
    };
  }
  async componentDidMount() {
    // Dynamically load the RunKit script
    let lastAssetId = await this.props.freeverseAPI.getLastAssetIdByUniverse();

    if (!lastAssetId || lastAssetId.length === 0) {
      lastAssetId = "ENTER_ASSET_ID";
    } else {
      lastAssetId = lastAssetId[0]; // Take the first asset ID from the array
    }
    this.setState({ lastAssetId });

    const script = document.createElement("script");
    console.log();
    script.src = "https://embed.runkit.com";
    script.async = true;
    script.onload = () => this.createNotebook();
    document.body.appendChild(script);
  }

  createNotebook() {
    if (!this.notebook) {
      const element = document.getElementById("runkit-container");
      this.notebook = window.RunKit.createNotebook({
        element,
        source: `
console.log('Starting to load all the required dependencies');

//The id of the last asset you created will be prefilled 
const asset = '${this.state.lastAssetId}';

// dependencies
require('utf-8-validate');
const identity = require('freeverse-crypto-js');
const { updateAssetOp, AtomicAssetOps } = require("freeverse-apisigner-js");
require('graphql');
const { GraphQLClient, gql } = require('graphql-request');
console.log('All required dependencies have been successfully loaded');

// These variables are prefilled from your account details
const pvk = '${this.props.universeInfo.privateKey}';
const uni = '${this.props.universeInfo.universeId}';
const endpoint = 'https://api.staging.blackhole.gorengine.com/';

// These will be the new properties of the asset, feel free to edit as you'd like
const updated_asset_props = {
    name: 'Supercool Dragon',
    description: 'Legendary creature that loves ice.',
    image: 'ipfs://QmPCHHeL1i6ZCUnQ1RdvQ5G3qccsjgQF8GkJrWAm54kdtB',
    animation_url: 'ipfs://QmefzYXCtUXudCy9LYjU4biapHJiP26EGYS8hQjpei472j',
    attributes: [
        {
        trait_type: 'Rarity',
        value: 'Common',
        },
        {
        trait_type: 'Level',
        value: 10,
        },
        {
        trait_type: 'Weight',
        value: 223.5,
        },
    ],
};
// This is the new metadata - this is private to the universe owner
const updated_asset_metadata = {
    private_data: 'that has been updated',
};

// the GraphQL Client, used to send and receive data
const graphQLClient = new GraphQLClient(endpoint, {
  jsonSerializer: {
  parse: JSON.parse,
  stringify: JSON.stringify,
  },
});

const run = async () => {
    // request the current nonce of the asset from the Living Assets API
    const data = await graphQLClient.request(
    // query
    gql\`
    query( $assetId: String! ) {
      assetById(
        id: $assetId
      ) {
        nonce
      }
    }\`,
    // variables
    {
      assetId: asset,
    },
  );
  const assetNonce = data.assetById.nonce;

    // create web3 account from the universe owner private key
    const universe_owner_account = identity.accountFromPrivateKey(pvk);

    // Use Freeverse Node libraries to create and sign the mutation
    const assetOps = new AtomicAssetOps({ universeId: uni });
    const op = updateAssetOp({
        nonce: assetNonce,
        assetId: asset,
        props: updated_asset_props,
        metadata: updated_asset_metadata,
    });
    assetOps.push({ op });
    const sig = assetOps.sign({ web3Account: universe_owner_account });
    const mutation = assetOps.mutation({ signature: sig });

    // send it to the endpoint to execute
    try {
      const mutationResponse = await graphQLClient.request(mutation);
      
      console.log("Asset updated successfully");
      console.log("Asset id:", asset);
      console.log("You can see your evolved asset in the scan by providing the asset id");
  
      }
      catch(error){
        console.log("Something went wrong while evolving the asset",error);
      } 
}
run();
                
                `,
        onLoad: () => {
          // Once the notebook is fully loaded and rendered, set loading to false
          this.setState({ loading: false });
        },
        nodeVersion: "18.x.x",
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="spinner"></div>
          </div>
        )}
        <div id="runkit-container"></div>
      </div>
    );
  }
}

export default UpdateAssetCode;
