// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
  background-color: transparent;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#main-content {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
  background-color: #584089;
}
`, "",{"version":3,"sources":["webpack://./src/components/sections/Footer.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":["html,\nbody {\n  height: 100%;\n  background-color: transparent;\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  min-height: 100%;\n}\n\n#main-content {\n  flex: 1 0 auto;\n}\n\nfooter {\n  flex-shrink: 0;\n  background-color: #584089;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
