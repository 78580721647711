import "./css/App.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes/routes";
import { AuthProvider } from "./utils/firebase";
import React, { useState, useEffect } from 'react';
import AlertModal from './utils/AlertModal.js'; // In order to modify the alert modals styles




function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // Override the default window.alert
  useEffect(() => {
    window.alert = (message) => {
      setModalMessage(message);
      setIsModalOpen(true);
    };
  }, []);


  return (
    <AuthProvider>
      <RouterProvider router={router} />
      <AlertModal isOpen={isModalOpen} message={modalMessage} onClose={() => setIsModalOpen(false)} />
    </AuthProvider>
  );
}
export default App;
