import PutForSaleCode from "../runkit/PutForSaleCode";

const PutForSale = ({ universeInfo, freeverseAPI }) => {
  return (
    <>
      <PutForSaleCode universeInfo={universeInfo} freeverseAPI={freeverseAPI} />
    </>
  );
};
export default PutForSale;
