import React, { useState, useEffect, useContext, useCallback } from "react";
import "./../css/Documentation.css";


function Documentation() {
  
    let srcconst = "";
    srcconst =`https://dev.livingassets.io`;
  
    useEffect(() => { 
        // When the component mounts, add a class to the body
        document.body.classList.add('noScrollbar'); // Hiding the scrollbar so we don't have 2 scrollbars together with the iframe

        // When the component unmounts, remove the class
        return () => {
            document.body.classList.remove('noScrollbar');
        };
    }, []); 

    return (
        <div class="parentDocumentation">
            <iframe class="GitBookDocumentation" src="https://dev.livingassets.io"/>
        </div>
    );
  }
  
  export default Documentation;
  